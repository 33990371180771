import React, { useRef } from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { isBrowser } from "../../../../context/ApolloContext"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { FaArrowRight } from "react-icons/fa"
const $ = isBrowser ? require("jquery") : {}
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
  cellAlign: "left",
}

const ProductSlider = ({ intl, data }) => {
  var prevNext = useRef()
  return (
    <ProductBoxWrapper>
      {isBrowser && (
        <Carousel className="carousel">
          <div style={{ width: "100%" }}>
            <Flickity
              flickityRef={c => (prevNext = c)}
              options={flickityOptions}
            >
              {data.map((item, index) => {
                return (
                  <ProductBox key={index.toString()} to={item.button_url}>
                    <ProductImage className="lazyload" data-src={item.image} />
                    {item.top_text && (
                      <TopTextBox>
                        <TopText
                          dangerouslySetInnerHTML={{
                            __html: item.top_text,
                          }}
                        />
                      </TopTextBox>
                    )}
                    <ContentBox>
                      {item.title && (
                        <ProductTitle
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}
                      {item.logo_image && (
                        <ProductLogo
                          className="lazyload"
                          data-src={item.logo_image}
                        />
                      )}
                      {item.button_url && (
                        <ProductButton to={item.button_url}>
                          <FaArrowRight
                            style={{ cursor: "pointer" }}
                            color="#fff"
                            size={18}
                          />
                        </ProductButton>
                      )}
                    </ContentBox>
                  </ProductBox>
                )
              })}
            </Flickity>
          </div>
        </Carousel>
      )}
    </ProductBoxWrapper>
  )
}

const ProductBoxWrapper = styled.div`
  display: flex;
  width: 100%;
`

const ProductBox = styled(Link)`
  display: flex;
  width: 100%;
  max-width: 230px;
  min-width: 230px;
  margin: 20px;
  box-shadow: 0 3px 20px rgb(94 178 166 / 40%);
  border-radius: 30px 0px 45px 0px;
  position: relative;
  cursor: pointer;
  align-items: center;

  @media (max-width: 420px) {
    margin: 10px 20px;
  }
`

const ProductImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;
`

const TopTextBox = styled.div`
  background: #262626;
  padding: 10px 20px;
  border-radius: 15px 0px 15px 0px;
  text-align: center;
  position: absolute;
  top: -5px;
  left: -5px;
  width: auto;
`

const TopText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #fff;
`

const ContentBox = styled.div`
  padding: 10px 20px;
  position: absolute;
  bottom: 15px;
  width: 100%;
`

const ProductTitle = styled.div`
  font-size: 13px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  color: #262626;
`

const ProductLogo = styled.img`
  object-fit: contain;
  margin: 0px;
  max-width: 130px;
  max-height: 35px;
  min-height: 35px;
`

const ProductButton = styled.div`
  background: #262626;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 20px;
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;

  @media (max-width: 850px) {
    padding: 0px;
  }

  .flickity-viewport {
    @media (max-width: 550px) {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
    }
  }

  .flickity-slider {
    display: flex;
    left: 2% !important;

    @media (max-width: 767px) {
      align-items: center;
    }

    @media (max-width: 550px) {
      left: 5% !important;
    }
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;
    top: 100%;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button {
    transform: translate(-50%, -50%);
    bottom: -60px;
    top: auto;
  }
  .flickity-prev-next-button.next {
    right: 35%;

    @media (max-width: 850px) {
      right: -20px;
    }

    @media (max-width: 550px) {
      right: 0px;
    }
  }
  .flickity-prev-next-button.previous {
    left: 38%;

    @media (max-width: 850px) {
      left: 20px;
    }

    @media (max-width: 550px) {
      left: 40px;
    }
  }
  .flickity-page-dots {
    bottom: -30px;
    max-width: 160px;
    margin: 0px auto;
    left: 0;
    right: 0;
  }

  .flickity-page-dots .dot {
    margin: 0 3px;
  }
`

export default injectIntl(ProductSlider)
