import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  padding: 0px 0px 20px;

  @media (max-width: 850px) {
    flex-direction: column-reverse;
    padding: 0px;
  }
`

const DesktopImageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 80px 0px;
  overflow: hidden;
  margin: ${({ direction }) =>
    direction ? "0px 10px 0px 0px" : "0px 0px 0px 10px"};

  @media (max-width: 850px) {
    margin: 0px 0px 20px;
  }

  @media (max-width: 550px) {
    border-radius: 0px;
    display: none;
  }
`

const MobileImageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 80px 0px;
  overflow: hidden;
  display: none;

  @media (max-width: 550px) {
    border-radius: 0px;
    display: block;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0;
  height: 100%;
  object-fit: cover;
`

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ direction }) =>
    direction ? "0px 0px 0px 10px" : "0px 10px 0px 0px"};

  @media (max-width: 850px) {
    margin: 0px;
  }

  @media (max-width: 550px) {
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 20px;
  }
`

const Title = styled.h6`
  width: 100%;
  font-size: 36px;
  line-height: 1.1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #000;
  margin: 0px 0px 20px;

  span {
    color: #ffab61;
  }

  @media (max-width: 550px) {
    font-size: 28px;
    margin: 0px 0px 10px;
  }
`

const TextBox = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  text-align: justify;

  @media (max-width: 550px) {
    margin: 0px;
  }

  img {
    max-width: 100%;
    margin: 0px 0px 15px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }

  ul {
    margin: 0px;
    list-style: none;
    font-size: 16px;
    line-height: 20px;
  }

  ul li {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    color: #2653a1;
    margin-bottom: 10px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    color: #2653a1;

    :hover {
      color: #2653a1;
      text-decoration: underline;
    }
  }
`

const PageContentSection = ({ intl, data, direction }) => {
  return (
    <RowWrapper direction={direction}>
      <ContentBox direction={direction}>
        {data.title && (
          <Title
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
        )}
        {data.text && (
          <TextBox
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
          />
        )}
      </ContentBox>
      {data.desktop_image && (
        <DesktopImageBox direction={direction}>
          <Image className="lazyload" data-src={data.desktop_image} alt="" />
        </DesktopImageBox>
      )}
      {data.mobile_image && (
        <MobileImageBox>
          <Image className="lazyload" data-src={data.mobile_image} alt="" />
        </MobileImageBox>
      )}
    </RowWrapper>
  )
}

export default injectIntl(PageContentSection)
