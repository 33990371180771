import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: linear-gradient(
    42.57deg,
    rgb(165 250 183 / 20%) 0%,
    rgb(0 196 193 / 20%) 52.23%,
    rgb(80 181 213 / 20%) 93.37%
  );
  border-radius: 100px 0px;
  padding: 40px 30px;
  margin: 30px 0px;
  align-items: flex-start;

  @media (max-width: 550px) {
    border-radius: 0px;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }
`

const DesktopImageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 80px 0px;
  overflow: hidden;
  margin: 0px 10px 0px 0px;
  max-width: 250px;

  @media (max-width: 550px) {
    border-radius: 0px;
    display: none;
  }
`

const MobileImageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 80px 0px;
  overflow: hidden;
  display: none;

  @media (max-width: 550px) {
    border-radius: 0px;
    display: block;
  }
`

const Image = styled.img`
  width: 100%;
  margin: 0;
  height: 100%;
  object-fit: cover;
`

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 10px;
  z-index: 1;

  @media (max-width: 550px) {
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 20px;
  }
`

const Title = styled.h6`
  width: 100%;
  font-size: 24px;
  line-height: 1.1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #ffab61;
  margin: 0px 0px 10px;
`

const TextBox = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  text-align: justify;

  @media (max-width: 550px) {
    margin: 0px;
  }

  img {
    max-width: 100%;
    margin: 0px 0px 15px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }

  ul {
    margin: 0px;
    list-style: none;
    font-size: 16px;
    line-height: 20px;
  }

  ul li {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    color: #2653a1;
    margin-bottom: 10px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    color: #2653a1;

    :hover {
      color: #2653a1;
      text-decoration: underline;
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 5px 0px 0px;
  z-index: 1;

  @media (max-width: 550px) {
    width: calc(100% - 10px);
    margin: 20px 5px 0px 5px;
    justify-content: center;
  }
`

const Button = styled(Link)`
  background: #262626;
  color: #fff;
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border-radius: 25px 0px;
  padding: 10px 20px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      42.57deg,
      #a5fab7 0%,
      #00c4c1 52.23%,
      #50b5d5 93.37%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const FirstMiddleSection = ({ intl, data, direction }) => {
  return (
    <RowWrapper direction={direction}>
      {data.desktop_image && (
        <DesktopImageBox direction={direction}>
          <Image className="lazyload" data-src={data.desktop_image} alt="" />
        </DesktopImageBox>
      )}
      {data.mobile_image && (
        <MobileImageBox>
          <Image className="lazyload" data-src={data.mobile_image} alt="" />
        </MobileImageBox>
      )}
      <ContentBox direction={direction}>
        {data.title && (
          <Title
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
        )}
        {data.text && (
          <TextBox
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
          />
        )}
        {data.button_text && data.button_url && (
          <ButtonWrapper>
            <Button to={data.button_url}>{data.button_text}</Button>
          </ButtonWrapper>
        )}
      </ContentBox>
    </RowWrapper>
  )
}

export default injectIntl(FirstMiddleSection)
