import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const LoopSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0px;
`

const Container = styled.div`
  width: 100%;
  position: relative;
`

const DesktopSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  background-size: contain;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileSection = styled.div`
  flex-direction: column;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    top: 20px;
    left: 20px;
    right: 20px;
    transform: translate(0px, 0px);
    width: calc(100% - 40px);
  }
`

const LoopTitle = styled.h6`
  color: #000;
  font-size: 36px;
  line-height: 1.1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  margin: 0px;
  text-align: center;
  position: relative;
  margin-bottom: 15px;

  @media (max-width: 380px) {
    font-size: 28px;
  }
`

const LoopText = styled.div`
  color: #000;
  font-size: 18px;
  line-height: 1.1;
  font-family: "Museo";
  font-weight: bold;
  margin: 0px;
  text-align: center;
  position: relative;
  margin-bottom: 15px;

  @media (max-width: 850px) {
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const LoopLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const LoopLinkText = styled.span`
  display: none;
`

const LoopButton = styled(Link)`
  font-size: 18px;
  font-family: "Museo";
  font-weight: bold;
  text-align: center;
  color: #000;
  text-decoration: underline;

  :hover {
    color: #000;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const Image = styled.img`
  margin: 0px;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  border-radius: 80px 0px;

  @media (max-width: 550px) {
    min-height: 310px;
    border-radius: 0px;
  }
`

export const LoopSection = ({ intl, data }) => (
  <LoopSectionWrapper>
    <Container>
      <LoopLink to="/shop/loop/">
        <LoopLinkText />
      </LoopLink>
      <DesktopSection>
        {data.desktop_image && (
          <Image className="lazyload" data-src={data.desktop_image} />
        )}
        <Content>
          {data.title && (
            <LoopTitle dangerouslySetInnerHTML={{ __html: data.title }} />
          )}
          {data.text && (
            <LoopText dangerouslySetInnerHTML={{ __html: data.text }} />
          )}

          {data.button_text && data.button_url && (
            <LoopButton to={data.button_url}>{data.button_text}</LoopButton>
          )}
        </Content>
      </DesktopSection>

      <MobileSection>
        {data.mobile_image && (
          <Image className="lazyload" data-src={data.mobile_image} />
        )}
        <Content>
          {data.title && (
            <LoopTitle dangerouslySetInnerHTML={{ __html: data.title }} />
          )}
          {data.text && (
            <LoopText dangerouslySetInnerHTML={{ __html: data.text }} />
          )}

          {data.button_text && data.button_url && (
            <LoopButton to={data.button_url}>{data.button_text}</LoopButton>
          )}
        </Content>
      </MobileSection>
    </Container>
  </LoopSectionWrapper>
)

export default injectIntl(LoopSection)
